import React from 'react';

import '../styles/Home.css'; 
import '../styles/SwitchPage.css';
import '../styles/Partners.css';
import '../styles/Maturity.css'; 
import '../styles/Migration.css'; 
import '../styles/Infrastructure.css';
import '../styles/Data.css';
import '../styles/Hometext.css';

import translations from '../context/translations'; 

import homeImage from './combinedhome/img/home.png'; //home1
import maturityImage from './combinedhome/img/maturity.png';
import MigrationImage from './combinedhome/img/migration1.png'; 
import InfrastructureImage from './combinedhome/img/infrastructure1.png';
import EconomicsImage from './combinedhome/img/economics3.png'
import MulticloudImage from './combinedhome/img/Multicloud2.png'

import OracleIMG from './combinedhome/img/Oracle.png'
import MicrosoftIMG from './combinedhome/img/Microsoft1.png'
import AwsIMG from './combinedhome/img/aws1.png'
import GoogleIMG from './combinedhome/img/google2.png'
import AlibabaIMG from './combinedhome/img/Alibaba.png'
import IbmIMG from './combinedhome/img/ibm.png'

const Home = ({ language }) => {
    const { homeTitle, homeText }= translations.en;

    return (
        <div className="home">
            <div className="text-image-container">
                <div className="text">
                    <h1 className="text-main">{homeTitle}</h1>
                    <p className="text-mini">{homeText}</p>
                </div>
                <div className="image">
                    <img src={homeImage} alt="Example" />
                </div>
            </div>
        </div>
    );
};

const SwitchPage = ({ language, onButtonClick }) => {
    const { button1, button2, button3, button4, button5} = translations.en;

    return (
        <div className="switchpage">
            <div className="switchpage-buttons-container">
                <button 
                    className="switchpage-button" 
                    onClick={() => onButtonClick('Maturity')}
                >
                    {button1}
                </button>
                <button 
                    className="switchpage-button" 
                    onClick={() => onButtonClick('Migration')}
                >
                    {button2}
                </button>
                <button 
                    className="switchpage-button" 
                    onClick={() => onButtonClick('Infrastructure')}
                >
                    {button3}
                </button>
                <button 
                    className="switchpage-button"
                    onClick={() => onButtonClick('Economics')}
                >
                    {button4}
                </button>

                <button 
                    className="switchpage-button"
                    onClick={() => onButtonClick('Multicloud')}
                >
                    {button5}
                </button>
            </div>
        </div>
    );
};


const Partners  = ({ language }) => {
    const { text40, text20, partnerButton, buttonText1 } = translations[language];

    return (
        <div className="container-wrapper">
            <div className="container">
                <div className="partners">
                    <div className="text-container">
                        <div className="text-40">{text40}</div>
                    </div>
                    {/*<div className="text-container">
                        <div className="text-30">{text30}</div>
                    </div>*/}
                    <div className="text-container">
                        <div className="text-20">{text20}</div>
                    </div>
                    <button className="partner-button">EXPERTS IN</button>
                    <div className="buttons-container">
                        <div className="padding-button">
                            <button>
                                <div className="button-content">
                                    <img src={MicrosoftIMG} alt="Image" className="button-image" />
                                    <div className="button-text">{buttonText1}</div>
                                    {/* Добавляем текст "Microsoft" под кнопкой */}
                                    <div className="partner-label"></div>

                                    <div className="partner-text-k">is a global technology leader, renowned 
                                        for its flagship products such as Windows, Microsoft Office, and 
                                        the Azure cloud platform. The company plays a pivotal role in enhancing
                                         productivity, advancing cloud computing, and driving innovation in artificial 
                                         intelligence. Additionally, Microsoft has a strong presence in the gaming industry
                                          with its Xbox brand.</div>

                                </div>
                            </button>
                            
                        </div>
                        
                        <div className="padding-button">
                            <button>
                                <div className="button-content">
                                    <img src={GoogleIMG} alt="Image" className="button-image" />
                                    <div className="partner-label"></div>
                                    <div className="partner-text-k">  is a global tech company, known for its 
                                        leading search engine and products like Google Ads, Google Cloud, YouTube,
                                         and Android. It emphasizes innovation in AI, data analytics, and digital
                                          advertising to enhance user experiences and advance technology.</div>
                                </div>
                            </button>
                        </div>


                        <div className="padding-button">
                            <button>
                                <div className="button-content">
                                    <img src={AlibabaIMG} alt="Image" className="button-image" />
                                    <div className="partner-label"></div>
                                    <div className="partner-text-k">is a major global e-commerce and technology company from China. It runs a large online marketplace, offers cloud computing via Alibaba Cloud, and provides digital and financial services. Alibaba drives innovation in retail, logistics, and AI, positioning itself as a key player in the global digital economy.</div>
                                </div>
                            </button>
                        </div>
                        
                        <div className="padding-button">
                            <button>
                                <div className="button-content">
                                    <img src={AwsIMG} alt="Image" className="button-image" />
                                    <div className="partner-label"></div>

                                    <div className="partner-text-k"> is Amazon's cloud platform, offering 
                                        on-demand services like computing, storage, databases, and advanced
                                         tools for machine learning, analytics, and IoT. It helps businesses 
                                         scale and innovate efficiently across various industries.</div>

                                </div>
                            </button>
                        </div>

                        <div className="padding-button">
                            <button>
                                <div className="button-content">
                                    <img src={IbmIMG} alt="Image" className="button-image" />
                                    <div className="partner-label"></div>
                                    <div className="partner-text-k"> is a global tech and consulting company, renowned for its innovation in cloud computing, AI, and quantum computing. Focused on enterprise solutions, IBM offers IT infrastructure, software, and services to support digital transformation. It is also known for its AI platform, IBM Watson, and leadership in hybrid cloud solutions.</div>
                                </div>
                            </button>
                        </div>

                        <div className="padding-button">
                            <button>
                                <div className="button-content">
                                    <img src={OracleIMG} alt="Image" className="button-image" />
                                    <div className="partner-label"></div>
                                    <div className="partner-text-k"> is a global tech company known for its 
                                        cloud solutions, databases, and enterprise software. It focuses on 
                                        cloud technology, data management, and business applications to drive
                                         digital transformation.</div>
                                </div>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

const Maturity = ({ language }) => {
    const maturityText = "Maturity Assessment Service"; // Static text for simplicity
    const additionalText = "We provide detailed and thorough insights into your entire technology infrastructure, helping you make well-informed decisions for your organization.";
    const additionalText1 = "Whether you’re aiming to benchmark your current IT governance practices against industry standards or you’re just beginning to develop them, we’re here to support you every step of the way.";
    return (
        <div className="engineering">
            <div className="engineering-content">
                <div className="engineering-image">
                    <img src={maturityImage} alt="Engineering and implementations" />
                </div>
                <div className="engineering-text">
                    <p className="main-text">{maturityText}</p>
                    <div className="additional-info">
                        <p>{additionalText}</p>
                    </div>
                    <div className="additional-info">
                        <p>{additionalText1}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};


const Migration = ({ language }) => {
    const MigrationText = "Cloud Migration Assessment"; // Пример текста
    const additionalText = "Detailed Infrastructure Performance Analysis. Gain a clear understanding of predicted costs and performance by evaluating how your current infrastructure will perform in the cloud.";

    return (
        <div className="services">
            <div className="home4-content">
                <div className="home4-text">
                    <p className="main-text">{MigrationText}</p>
                    <div className="additional-info">
                        <p>{additionalText}</p>
                    </div>
                </div>
                <div className="home4-image">
                    <img src={MigrationImage} alt="Cloud Computing Services" />
                </div>
            </div>
        </div>
    );
};

const Infrastructure = ({ language }) => {
    const InfrastructureText = "Cloud Infrastructure Migration Service"; // Example text
    const additionalText = "Leveraging best-practice frameworks and migration experts, with flexible timing through an offshore model, we offer a three-step process to ensure a swift, cost-effective, and low-risk migration for:";
    const additionalText1 = "Databases";
    const additionalText2 = "Applications";
    const additionalText3 = "Platforms";
    const additionalText4 = "Middleware";
    const additionalText5 = "We handle transitions from on-premises setups to any cloud or hybrid environment.";

    return (
        <div className="home5">
            <div className="home5-content">
                <div className="home5-image-container">
                    <img src={InfrastructureImage} alt="Cloud Transformation" />
                </div>
                <div className="home5-text-container">
                    <p className="main-text">{InfrastructureText}</p>
                    <div className="additional-info">
                        <p>{additionalText}</p>
                    </div>
                    <div className="additional-info">
                        <p>{additionalText1}</p>
                    </div>
                    <div className="additional-info">
                        <p>{additionalText2}</p>
                    </div>
                    <div className="additional-info">
                        <p>{additionalText3}</p>
                    </div>
                    <div className="additional-info">
                        <p>{additionalText4}</p>
                    </div>
                    <div className="additional-info">
                        <p>{additionalText5}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Economics = ({ language }) => {
    const EconomicsText = "Cloud economics"; // Пример текста
    const additionalText = "With CloudClou, you are assured of a fully managed solution for Software and Cloud Asset Management.";
    const additionalText1 = "Our services are crafted to optimize your IT investments, allowing you to concentrate on what truly matters to your business.";

    return (
        <div className="services">
            <div className="home4-content">
                <div className="home4-text">
                    <p className="main-text">{EconomicsText}</p>
                    <div className="additional-info">
                        <p>{additionalText}</p>
                    </div>
                    <div className="additional-info">
                        <p>{additionalText1}</p>
                    </div>
                </div>
                <div className="home4-image">
                    <img src={EconomicsImage} alt="Cloud Computing Services" />
                </div>
            </div>
        </div>
    );
    
};


const Multicloud = ({ language }) => {
    const MulticloudText = "Multicloud assessment"; // Example text
    const additionalText = "Our Multicloud Assessment provides a thorough comparative analysis of different cloud providers, helping you understand the strengths and weaknesses of each option. We evaluate key factors such as cost, performance, security, and features to offer a clear picture of how various providers stack up against each other. ";
    const additionalText1 = "Based on this detailed analysis, we provide a well-supported recommendation for selecting or switching to the most suitable cloud provider for your needs. This ensures that you make informed decisions aligned with your organization's strategic goals and operational requirements.";

    return (
        <div className="home5">
            <div className="home5-content">
                <div className="home5-image-container">
                    <img src={MulticloudImage} alt="Cloud Transformation" />
                </div>
                <div className="home5-text-container">
                    <p className="main-text">{MulticloudText}</p>
                    <div className="additional-info">
                        <p>{additionalText}</p>
                    </div>
                    <div className="additional-info">
                        <p>{additionalText1}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};



const Connection = ({ language }) => {
    const { homeTitle, homeText }= translations.en;

    return (
        <div className="hometext">

            {/* New bottom container */}
            <div className="bottom-container">
                <div className="bottom-sub-container">
                    <div className="text-container-connection">
                        <p>Telegram</p>
                    </div>
                    <div className="text-container-connection">
                        <p>+71234567890</p>
                    </div>
                </div>
                <div className="bottom-sub-container">
                    <div className="text-container-connection">
                        <p>WhatsApp</p>
                    </div>
                    <div className="text-container-connection">
                        <p>+71234567890</p>
                    </div>
                </div>
                <div className="bottom-sub-container">
                    <div className="text-container-connection">
                        <p>Maps</p>
                    </div>
                    <div className="text-container-connection">
                        <p>Cloud Clou Kft., 2636 Tésa, Ady Endre utca 11</p>
                    </div>
                </div>
                <div className="bottom-sub-container">
                    <div className="text-container-connection">
                        <p>Email</p>
                    </div>
                    <div className="text-container-connection">
                        <p>cloudclou.net</p>
                    </div>
                </div>
            </div>
        </div>
    );
};



export { Home, SwitchPage, Partners , Maturity, Migration, Infrastructure, Economics, Connection, Multicloud};
