import React from 'react';
import './Footer.css';

import CloudClou from './combinedhome/img/CloudClouWhite.png';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="waves">
        <div className="wave" id="wave1"></div>
        <div className="wave" id="wave2"></div>
        <div className="wave" id="wave3"></div>
        <div className="wave" id="wave4"></div>
      </div>
      
      <div className="footer-content">
        {/* Footer Logo */}
        <div className="footer-column footer-logo">
          <img src={CloudClou} alt="CloudClou" />
        </div>

        {/* Services Column */}
        <div className="footer-column">
          <ul className="menu2">
            <li className="menu__item">
              <p className="menu__link">Services</p>
            </li>
            <li className="menu__item">
              <a className="menu__link">Maturity Assessment Service</a>
            </li>
            <li className="menu__item">
              <a className="menu__link">Cloud Migration Assessment</a>
            </li>
            <li className="menu__item">
              <a className="menu__link">Cloud Infrastructure Migration Service</a>
            </li>
            <li className="menu__item">
              <a className="menu__link">Cloud Economics</a>
            </li>
            <li className="menu__item">
              <a className="menu__link">Multicloud Assessment</a>
            </li>
          </ul>
        </div>

        {/* Contact Column */}
        <div className="footer-column">
          <ul className="menu2">
            <li className="menu__item">
              <a className="menu__link">Contact</a>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="https://t.me/the_CloudClou" target="_blank" rel="noopener noreferrer">Telegram</a>
              <p>@the_CloudClou</p>
            </li>
            <li className="menu__item">
              <a className="menu__link">WhatsApp</a>
              <p>+36 20 315 4303</p>
            </li>
            <li className="menu__item">
              <a className="menu__link">Maps</a>
              <p>Cloud Clou Kft., 2636 Tésa, Ady Endre utca 11</p>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="mailto:ankupr@cloudclou.net">Email</a>
              <p>ankupr@cloudclou.net</p>
            </li>
          </ul>
        </div>

        {/* Partners Column 
        <div className="footer-column">
          <ul className="menu2">
            <li className="menu__item">
              <a className="menu__link">Partners</a>
            </li>
          <div className="partners-logos">
            <ul>
              <li><img src={MicrosoftIMG} alt="Microsoft" /></li>
              <li><img src={AwsIMG} alt="AWS" /></li>
              <li><img src={GoogleIMG} alt="Google" /></li>
              <li><img src={AlibabaIMG} alt="Alibaba" /></li>
              <li><img src={IbmIMG} alt="IBM" /></li>
              </ul>
          </div>
          </ul>
        </div>*/}
        
      </div>
      
      
      <p>Cloud Clou &copy; 2024</p>
    </footer>
  );
};

export default Footer;
